@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'InterBlack';
  src: local('Inter-Black'),
  url('./assests/fonts/Inter-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'InterBold';
  src: local('Inter-Bold'),
  url('./assests/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'InterExtraBold';
  src: local('Inter-ExtraBold'),
  url('./assests/fonts/Inter-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'InterExtraLight';
  src: local('Inter-ExtraLight'),
  url('./assests/fonts/Inter-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'InterLight';
  src: local('Inter-Light'),
  url('./assests/fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'InterMedium';
  src: local('Inter-Medium'),
  url('./assests/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'InterRegular';
  src: local('Inter-Regular'),
  url('./assests/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'InterSemibold';
  src: local('Inter-Semibold'),
  url('./assests/fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'InterThin';
  src: local('Inter-Thin'),
  url('./assests/fonts/Inter-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'LatoRegular';
  src: local('Lato-Regular'),
  url('./assests/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'LatoBold';
  src: local('Lato-Bold'),
  url('./assests/fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratRegular';
  src: local('Montserrat-Regular'),
  url('./assests/fonts/Montserrat-Regular.ttf') format('truetype');
}

.dFlex {
  display: flex;
}
.justifyContentStart {
  justify-content: start;
}
.justifyContentSpaceBetween {
  justify-content: space-between;
}
.alignItemsCenter {
  align-items: center;
}
.textCenter {
  text-align: center!important;

  td {
    text-align: center!important;
  }
}

.textLeft {
  text-align: left!important;
}
.pl0 {
  padding-left: 0!important;
}
.pr0 {
  padding-right: 0!important;
}