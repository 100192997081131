table, table td, table th {
  border: 1px solid #F9FAFB;
  border-collapse: collapse;
}

.textGreen {
  margin-top: 5px;
  color: #00AD64;
  text-decoration: underline;
}
.textYellow {
  margin-top: 5px;
  color: #FFB905;
  text-decoration: underline;
}
.etroContainer {
  padding: 0 50px;
}

.etroContainerM {
  margin: 0 50px;
}

.formWrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  max-height: 50px;
}

.noResultsWrapper {
  border: 1px solid #A7A7AC;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tableOptions {
  display: flex;
  justify-content: space-between;
  margin-top: 37px;
  margin-bottom: 37px;
}

.paginationTitle {
  font-family: 'Arial';
  font-size: 28px;
  font-weight: bold;
  margin-right: 32px;
}

.errorTitle {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 29px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #888A93;
}
.errorSubtext {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 19px;
  color: #888A93;
}

.bgTableGrey {
  background-color: #F9FAFB;
}

.tableBorderColor {
  table, th, tr, td {
    border-color: #DBDBE0;
    font-family: 'LatoRegular';
    font-weight: 700;
  }
  td, th {
    padding: 8px 15px 8px 5px;
    text-align: left;
    font-size: 14px;
    color: #808187;
    min-width: 80px;

    &.divideLeft {
      border-left: 1px double #a2a1a5;
    }
    &.divideRight {
      border-right: 1px double #a2a1a5;
    }
    &.boldText {
      font-weight: 700;
    }
    &.thinText {
      font-weight: 400;
    }
  }

  .tdSubBudget {
    font-size: 12px;
    color: #C8C7CB;
  }
  .plannedBudget {
    font-size: 12px;
    text-align: right;
    margin-top: 5px;
    color: #B3B3B3;
  }
}

.textRight {
  text-align: right!important;
  padding-right: 5px!important;
  padding-left: 15px!important;
}

.targetSalesTableWrapper {
  overflow: hidden;
  overflow: scroll;
  scrollbar-color: #DBDBE0 #fff;
  scrollbar-width: thin;
  /* height: 60vh;
  min-height: 600px; */
  max-height: 72vh;

  &.etroContainer {
    padding-left: 0;
    margin-left: 50px;
  }
}
.formControl {
  position: relative;
  display: inline-block;
  &.left {
    input {
      padding-left: 25px;
    }
  }
  &.right {
    input {
      padding-right: 25px;
    }
  }

  .inputAdornment {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.left {
      left: 10px;
    }
    &.right {
      right: 5px;
    }
  }
  input {
    text-align: right;
    width: 15ch;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    border-radius: 1px;
    border-color: #E5E5E8;
  }
}

.customDropdown {
  position: absolute;
  z-index: 13;
  background-color: white;
  border: 1px solid #EAECF0;
  box-shadow:inset 0px 0px 0px 1px #EAECF0;
  border-radius: 10px;
  padding: 20px;
  top: 130%;
}

.customDropdown {
  .inputTitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 20px;
  }
  .inputLabel {
    color: #888A93;
    font-family: "LatoRegular";
    margin-bottom: 15px;
  }
  .formControl {
    input {
      width: 100%;
      color: #888A93;
    }
    .inputAdornment {
      color: #888A93;
    }
  }
  .prevYearBtnsFooter {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
  
    button {
      width: 50%;
    }
  }
}

#countries-label,
#years-label {
  top: -8px;

  &.Mui-focused {
    top: 0px;
  }
  &.MuiFormLabel-filled {
    top: 0px;
  }
}

#scrollableTable {
  border-collapse: separate;
  border-spacing: 0;
  th, table td {
    white-space: nowrap;
    /* background-color: red; */
    background-color: #F9FAFB;
  }
  tr th:first-child, td:first-child, td.sticky, th.sticky {
    position: sticky;
    width: 80px;
    left: 0;
    z-index: 10;
    /* background-color: rgb(81, 255, 0); */
    background-color: #F9FAFB;

    &.sticky1 {
      min-width: 60px;
      max-width: 60px;
      padding-right: 0;
    }
    &.sticky2 {
      left: 60px;
    }
    &.sticky3 {
      left: 140px;
    }
    &.sticky4 {
      left: 250px;
    }
    &.sticky5 {
      left: 350px;
    }
    &.sticky6 {
      left: 430px;
    }
    &.sticky7 {
      left: 510px
    }
    &.sticky8 {
      left: 590px;
    }
    &.sticky9 {
      left: 670px;
    }
    &.sticky_2_1 {
      padding-right: 5px;
      min-width: 190px;
      left: 60px;
    }
    &.sticky_3_6 {
      left: 250px;
    }
    &.sticky_row_2_col_1 {
      max-width: 60px;
      min-width: 60px;
      padding-right: 0;
    }
    &.sticky_row_2_col_2 {
      left: 60px;
      min-width: 80px;
    }
    &.sticky_row_2_col_3 {
      left: 140px;
    }
    &.sticky_row_2_col_4 {
      min-width: 100px;
      left: 250px;
    }
    &.sticky_row_2_col_5 {
      left: 350px;
    }
    &.sticky_row_2_col_6 {
      left: 430px;
    }
    &.sticky_row_2_col_7 {
      left: 510px;
    }
    &.sticky_row_2_col_8 {
      left: 590px;
    }
    &.sticky_row_2_col_9 {
      left: 670px;
    }
  }
  tr th:first-child, tr th.sticky {
    z-index: 11;
    /* background-color: rgb(89, 0, 255); */
    background-color: #F9FAFB;
  }
  tr th {
    position: sticky;
    top: 0;
    z-index: 1;
    /* background-color: rgb(0, 204, 255); */
    background-color: #F9FAFB;
  }
  tr th.sticky_row_2 {
    top: 37px;
  }

  .totalRow {
    td {
      position: sticky;
      bottom: 0;
      z-index: 11;
      background-color: #F9FAFB;

      &.sticky {
        z-index: 12;
      }
    }
  }
}

.pinIcon {
  cursor: pointer;
}
input:disabled {
  background-color: #fefefe;
}
#scrollableTable td.weekTd {
  z-index: 0;
}