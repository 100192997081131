.budgetWrapper {
  display: flex;
  justify-content: space-between;
}

.tableWrapper {
  padding: 0 30px;
}

.titleWrapper {
  padding: 0 30px;
  align-items: center;
}

.monthlySalesTable {
  &.saTurnOverTable {
    border-collapse: separate;
    border-spacing: 0;
    th {
      padding: 12px 12px 12px 24px;
    }
    td {
      padding: 5px 0;

      &.leftPadding {
        padding-left: 5px;
      }
    }
  }

  .splitCols {
    th {
      max-width: 80px;
    }
  }

  input {
    max-width: 80px;
    border-color: #E5E5E8;
  }
  td {
    &.planned {
      color: #636363;
    }
    &.actual {
      color: #A4A6AE;
    }
    &.ratio {
      color: #FFB905;
      &.green {
        color: #00AD64;
      }
    }
  }
}