.budgetWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  .budgetItem {
    border: 1px solid #EEEEEE;
    border-radius: 10px;
    width: 100%;
    padding: 24px 24px 20px 24px;

    .title {
      font-family: 'MontserratRegular';
      font-size: 14px;
      color: #8280AC;
    }
    .value {
      font-size: 22px;
      font-family: 'InterSemibold';
      margin-top: 15px;
    }
  }
}



.titleWrapper {
  padding: 0 30px;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
}

.underTarget {
  color: #DC0000;
}

.monthlySalesTableWrapper {
  height: 70vh;
  min-height: 600px;
  overflow-y: scroll;
}

.monthlySalesTable {
  border-left: none;
  border-right: none;
  border-spacing: 0;
  th, td {
    border-color: #DBDBE0;
    padding: 8px 20px 8px 20px;
    font-size: 14px;
    text-align: left;
    font-family: "LatoRegular";
    font-weight: 400;
    color: #888A93;

    &.isBold {
      font-weight: 700;
      color: #2C2C2E;
    }
  }
  th {
    position: sticky;
    top: -1px;
    z-index: 12;
    background-color: #F9FAFB;
    padding: 24px 20px 24px 20px;
    &.short {
      width: 90px;
    }
    &.half {
      width: 50%;
    }
  }

  .footer {
    td {
      padding: 24px 20px 24px 20px;
    }
  }

  input:focus {
    color: #434343;
  }

  .totalsRow {
    td {
      position: sticky;
      bottom: -1px;
      background-color: #F9FAFB;
    }
  }
}
.borderLeftNone {
  border-left: none;
}
.borderRightNone {
  border-right: none;
}

.tabWrapper {
  display: flex;
  justify-content: end;
  gap: 5px;
}
.tabLink {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  cursor: pointer;
}
.splitBudget {
  color: #4C4C4E;
  font-family: "LatoRegular";
}
.resetAll {
  color: #888A93;
  font-family: "MontserratRegular";
}

